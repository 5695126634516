"use client";

import NotFound from "@components/Errors/NotFound";
import { Flex } from "@radix-ui/themes";
import { useTranslations } from "next-intl";

export default function ResourceNotFound() {
  const t = useTranslations("ResourceNotFound");
  return (
    <Flex
      direction={"column"}
      style={{ minHeight: "100vh" }}
      justify={"center"}
    >
      <NotFound title={t("title")} message={t("message")} cta={t("cta")} />
    </Flex>
  );
}
