"use client";
import styled from "styled-components";

import { Flex, Link } from "@radix-ui/themes";

type NotFoundProps = {
  title: string;
  message: string;
  cta: string;
};
export default function NotFound({ title, message, cta }: NotFoundProps) {
  return (
    <Flex
      direction={"column"}
      justify={"center"}
      align={"center"}
      flexGrow={"1"}
      gap={"3"}
      p={"3"}
    >
      <Flex direction={"row"}>
        <Title>{title}</Title>
        <SubTitle>{message}</SubTitle>
      </Flex>
      <Link href="/">{cta}</Link>
    </Flex>
  );
}

const Title = styled.h1`
  display: inline-block;
  margin: 0 20px 0 0;
  padding-right: 23px;
  font-size: var(--font-size-24);
  font-weight: 500;
  line-height: 49px;
  border-right: 1px solid rgba(0, 0, 0, 0.3);
  overflow-wrap: break-word;
`;

const SubTitle = styled.h2`
  font-size: var(--font-size-16);
  font-weight: 400;
  line-height: 49px;
  margin: 0;
  overflow-wrap: break-word;
`;
